<template>
  <Field
    v-slot="{ errors }"
    v-model="modelValue"
    :type="props.type"
    :name="props.name"
    :rules="props.rules"
    :label="props.label"
  >
    <div class="flex flex-col">
      <UiCheckbox
        v-bind="{
          ...props,
          'class': $attrs.class,
          'data-test': $attrs?.['data-test'] ?? '',
        }"
        v-model="modelValue"
        value="true"
        :error="errors.length > 0"
        :indeterminate="props.indeterminate"
        @change="onChange"
      >
        <template
          v-for="(_, name) in $slots"
          #[name]="slotData"
        >
          <slot
            :name="name"
            v-bind="{ ...slotData, errors }"
          />
        </template>
      </UiCheckbox>

      <slot
        v-if="props.showValidationMessageOutsideInput"
        name="error"
        :errors="errors"
      >
        <UiFieldMsg
          :class="props.errorMessageClass"
          :errors="errors"
          :additional-msg="additionalMsg"
        />
      </slot>
    </div>
  </Field>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Field } from 'vee-validate'
import UiFieldMsg from '../UiFieldMsg/UiFieldMsg.vue'
import UiCheckbox from '../UiCheckbox/UiCheckbox.vue'
import type { UiFormField } from '../UiForm.types'
import type { SizeVariantType } from '../UiCheckbox/UiCheckbox.types'

interface UiFormFieldCheckbox extends UiFormField {
  size?: SizeVariantType
  containerClass?: string[] | string
  errorMessageClass?: string[] | string
  modelValue?: boolean
  indeterminate?: boolean
}

const props = withDefaults(defineProps<UiFormFieldCheckbox>(), {
  id: '',
  label: '',
  type: '',
  name: '',
  rules: '',
  additionalMsg: '',
  showValidationMessageOutsideInput: true,
  variant: 'secondary',
  size: 'big',
  labelClick: false,
  indeterminate: false,
  containerClass: () => [],
  errorMessageClass: () => [],
})
const emit = defineEmits<{
  (e: 'change', value: Event): void
}>()

function onChange(event: Event) {
  emit('change', event)
}
const modelValue = ref(props.modelValue ?? false)
</script>
